'use strict';
require("packs/helper/accordion")
require("jquery")
require("packs/slick")

class Homepage {
  constructor() {
    document.addEventListener("turbo:load", this.init.bind(this));
  }

  loadWelcomeVideo() {
    const vidElement = document.querySelector('.welcome-video-video');
    const vidThumbnail = document.querySelector('.welcome-video-thumbnail');

    vidElement.src = vidElement.dataset.src;
    vidElement.load();

    vidElement.addEventListener('loadeddata', () => {
      vidElement.style.display = 'block';
      vidThumbnail.remove();
    });
  }

  loadSliderImages() {
    const sliderElement = document.querySelectorAll('.sliderLazyElement');

    [...sliderElement].map(el => {
      const imgTag = document.createElement('img');
      imgTag.src = el.dataset.bg;

      imgTag.addEventListener('load', () => {
        imgTag.remove();
        el.classList.remove('welcome-slider-loader');
        el.style.backgroundImage = `url('${imgTag.src}')`;
      });
    });
  }

  artworkGallery() {
    $("#artworkSlider").slick({
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 3,
      slidesToScroll: 1,
      pauseOnFocus: false,
      pauseOnHover: false,
      prevArrow: $(".artworkArrwsPrev"),
      nextArrow: $(".artworkArrwsNext"),
      responsive: [
        {
          breakpoint: 768,
          settings: { slidesToShow: 1 }
        },
      ]
    });
  }

  madeInSessionGallery() {
    $("#madeInSessionSlider").slick({
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 5,
      slidesToScroll: 1,
      pauseOnFocus: false,
      pauseOnHover: false,
      prevArrow: $(".sessionArrwsPrev"),
      nextArrow: $(".sessionArrwsNext"),
      responsive: [
        {
          breakpoint: 1300,
          settings: { slidesToShow: 4 }
        },
        {
          breakpoint: 1070,
          settings: { slidesToShow: 3 }
        },
        {
          breakpoint: 860,
          settings: { slidesToShow: 2 }
        },
        {
          breakpoint: 360,
          settings: { slidesToShow: 1 }
        }
      ]
    });
  }

  init() {
    this.loadWelcomeVideo();
    this.artworkGallery();
    this.madeInSessionGallery();
    this.loadSliderImages();
  }
}

new Homepage();
