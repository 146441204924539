'use strict';

document.addEventListener("turbo:load", () => {
  const btn = document.getElementById('accordionWrapper');
  const allBtns = document.querySelectorAll('.accordionBtn');
  const allBody = document.querySelectorAll('.accordionBody');

  btn?.addEventListener('click', function(e) {
    const clicked = e.target.closest('.accordionBtn');

    if (!clicked) return;

    clicked.classList.toggle('active');
    document.getElementById(clicked.dataset.target).classList.toggle('active');
  });
});
